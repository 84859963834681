export function checkUCPTargetingsCoverage(ucpTargetings, device) {
    if (ucpTargetings.length === 0) {
        return 'not enabled on UCP';
    }
    const ucpTargetingsCheck = [];
    ucpTargetings.find((value) => {
        var _a, _b, _c, _d;
        const message = `UCPTargeting(${value.id}): doesn't cover `;
        if (value.wikiId && value.wikiId !== device.pageContext.pId) {
            ucpTargetingsCheck.push(message + 'wikiId');
            return false;
        }
        if (value.wikiId && value.articleIds.length && !value.articleIds.includes(device.pageContext.pgId)) {
            ucpTargetingsCheck.push(message + 'articleId');
            return false;
        }
        // On f2 skins is undefined
        if (device.pageContext.p !== 'f2') {
            if (value.skins.length && !value.skins.includes(device.pageContext.pVar)) {
                ucpTargetingsCheck.push(message + 'skin');
                return false;
            }
        }
        if (value.geos.length && !value.geos.includes(device.geo)) {
            ucpTargetingsCheck.push(message + 'geo');
            return false;
        }
        if (value.genres.length &&
            (!((_c = (_b = (_a = device.pageContext) === null || _a === void 0 ? void 0 : _a.adTags) === null || _b === void 0 ? void 0 : _b.gnre) === null || _c === void 0 ? void 0 : _c.length) ||
                !value.genres.find((value) => device.pageContext.adTags.gnre.includes(value)))) {
            ucpTargetingsCheck.push(message + 'genres');
            return false;
        }
        // On f2 window.mw is undefined
        if (window.mw) {
            const wikiCategories = window.mw.config.get('wikiCategories');
            if (value.categories != null &&
                value.categories.length &&
                (!wikiCategories || !value.categories.some((category) => wikiCategories.includes(category)))) {
                ucpTargetingsCheck.push(message + 'categories');
                return false;
            }
            const wikiVertical = window.mw.config.get('wikiVertical');
            const hasValidVerticals = ((_d = value.verticals) === null || _d === void 0 ? void 0 : _d.length) && (!wikiVertical || !value.verticals.includes(wikiVertical));
            if (hasValidVerticals) {
                ucpTargetingsCheck.push(`${message} vertical`);
                return false;
            }
        }
        ucpTargetingsCheck.splice(0, ucpTargetingsCheck.length);
        return true;
    });
    return ((ucpTargetingsCheck.length &&
        ucpTargetingsCheck.reduce((previousValue, currentValue) => previousValue + ', ' + currentValue)) ||
        null);
}
