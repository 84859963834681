export function getMockedSponsoredLogos() {
    return [
        {
            id: -1,
            widgetType: 'sponsored-logo',
            active: true,
            startDateTime: null,
            endDateTime: null,
            ucpTargetings: [
                {
                    id: -1,
                    wikiId: null,
                    articleIds: [],
                    skins: [],
                    geos: [],
                    genres: [],
                    categories: [],
                    verticals: [],
                },
            ],
            states: [
                {
                    id: -1,
                    startDateTime: null,
                    clickThroughUrl: 'https://fandom.com',
                    ctaText: 'Premiers November 11, 2022! [**Click here!**](https://marvel.fandom.com/wiki/Black_Panther:_Wakanda_Forever)',
                    sponsorText: 'wakanda forever',
                    images: [
                        {
                            id: -1,
                            size: {
                                width: 36,
                                height: 36,
                            },
                            url: 'https://static.wikia.nocookie.net/fandom-ae-assets/programmatic/widgets/sponsored-logo-preview/sponsored-logo-preview-36x36-v1.png',
                        },
                    ],
                },
            ],
        },
    ];
}
