export function getMockedCountdownClocks() {
    const countdownEndDateTime = new Date();
    countdownEndDateTime.setDate(countdownEndDateTime.getDate() + 1);
    return [
        {
            id: -1,
            widgetType: 'countdown-clock',
            active: true,
            startDateTime: null,
            endDateTime: countdownEndDateTime.toISOString(),
            countdownEndDateTime: countdownEndDateTime.toISOString(),
            ucpTargetings: [
                {
                    id: -1,
                    wikiId: null,
                    articleIds: [],
                    skins: [],
                    geos: [],
                    genres: [],
                    categories: [],
                    verticals: [],
                },
            ],
            states: [
                {
                    id: -1,
                    startDateTime: null,
                    clickThroughUrl: 'https://fandom.com',
                    ctaText: '',
                    images: [
                        {
                            id: -1,
                            size: {
                                width: 300,
                                height: 120,
                            },
                            url: 'https://static.wikia.nocookie.net/fandom-ae-assets/programmatic/widgets/countdown-clock-preview/countdown-clock-preview-PRE-300x120-v1.jpg',
                        },
                        {
                            id: -2,
                            size: {
                                width: 300,
                                height: 450,
                            },
                            url: 'https://static.wikia.nocookie.net/fandom-ae-assets/programmatic/widgets/countdown-clock-preview/countdown-clock-preview-PRE-300x450-v1.jpg',
                        },
                    ],
                },
            ],
        },
    ];
}
